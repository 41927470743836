import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/EcoClub/vanmahotsav2023/1.jpg';
import p2 from 'assests/Photos/Clubs/EcoClub/vanmahotsav2023/2.jpg';
import p3 from 'assests/Photos/Clubs/EcoClub/vanmahotsav2023/3.jpg';
import p4 from 'assests/Photos/Clubs/EcoClub/vanmahotsav2023/4.jpg';

import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';


const VanMahotsav2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Van Mahotsav
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Classes: 1 & 2  &  Date: 18 JULY 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Van Mahotsav is an annual tree planting movement in India which began in 1950. The name 
        Van Mahotsav means the festival of trees. Over the years, it has grown in significance, 
        leading to the planting of millions of saplings along the country.
        <br></br>
        The students of Class 1 celebrated this festival by sowing marigold flower seeds in the 
        school premises and vowed to nurture the plants by watering the soil regularly.
        <br></br>
        Meanwhile the students of Class 2 drew beautiful sketches on ‘Gardening at Home’ and wrote a 
        few sentences on different ways to grow their needs in limited space. They emphasized the 
        importance of leading a healthy life by connecting with nature through pesticide free 
        gardening.
        <br></br>
        These activities served as an excellent opportunity to instill personal and social 
        responsibility in our students towards safeguarding trees and forests. The focus of these 
        celebrations was to establish a personal connect between the students and trees through 
        experiential learning, reflection and creativity.
        <br></br>
        Van Mahotsav week turned out to be a great success, raising awareness about the green cover 
        around us. It also helped in strengthening the bond between plants and humans. Students 
        gained valuable knowledge and will always cherish the memories of this important occasion.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"He who plants a tree, plants hope"

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Ecoclub2023 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default VanMahotsav2023;